import React from "react";
import style from "./index.module.scss";

import WithPadding from "@components/WithPadding";
import links from "@utils/navigation-links.json";

import LogoImg from "@assets/logo.svg";

import useFooter from "./useFooter";

export default function Footer() {
  const { data } = useFooter();

  return (
    <main className={style.wrapper}>
      <WithPadding className={style.inner}>
        <section className={style.top}>
          <article className={style.logo}>
            <img src={LogoImg} alt="" />
          </article>
          <article className={style.linksSection}>
            <header>Explore</header>
            <nav className={style.links}>
              {links.map((link) => (
                <a key={link.name} href={link.link}>
                  {link.name}
                </a>
              ))}
            </nav>
          </article>
          <article className={style.address}>
            <header>Address</header>
            <div>{data.about.address}</div>
          </article>
          <article className={style.contact}>
            <header>Hear From Us</header>
            <div>Phone: {data.about.phone}</div>
            <div>Email: {data.about.email}</div>
            <div>Book a free demo class</div>
          </article>
          <article className={style.socialHandles}>
            <header>Connect with Us</header>
            <div>
              {data.socials.map((icon) => (
                <span key={icon.icon}>
                  <a href={icon.link} target="_blank" rel="noreferrer">
                    <img src={icon.icon} alt="" />
                  </a>
                </span>
              ))}
            </div>
          </article>
        </section>

        <article className={style.bottom}>
          <p>
          Little Genie is an online kindergarten program that facilitates the growth of your child in all aspects,
          let it be cooking, learning, experimenting, crafting, or painting. Everything is at a single destination.
          This is a one-year online kindergarten program carefully researched and put together to bring out the best
          in our children. This curriculum is flexible and personalized which takes into account the heterogeneity
          of the learner and learning styles.
          </p>

          <p>
          This online program is endowed with immense attention to nurturing linguistic and logical reasoning ability
          through adopting an innovative method to stimulate interest and attention in the child.
          </p>

          <p>
          Little Genie is an innovative concept that breaks all the rules and limitations of traditional KG classes.
          We Team-INTERVAL believe that every child has a unique way of learning, growing, and achieving. And because
          of that, the Little Genie program is a very flexible and personalized online KG program. This will allow us
          to have more one-on-one time with each child and work on their individual goals. We will be able to do this
          through our unique curriculum and our cutting-edge technology. We are very excited to introduce Little Genie
          to you and your child.
          </p>

          <p>
          So what is Little Genie?
          This online kindergarten course is designed for preschool kids to contribute to their development in studies
          and other activities by providing essential basic education.
          </p>

        </article>
      </WithPadding>
    </main>
  );
}
