import { useState, useEffect } from "react";

import { getAbout, getSocials } from "@apis/general";
import { sortArrOfObjects } from "@utils/helpers";

export default function useFooter() {
  const [data, setData] = useState({ socials: [], about: { phone: "", email: "", address: "" } });

  function populateFooterData() {
    getAbout()
      .then((resp) => {
        setData((prev) => ({
          ...prev,
          about: { phone: resp.data.phone_number, email: resp.data.email, address: resp.data.address },
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    getSocials().then((resp) => {
      let sorted = sortArrOfObjects(resp.data, "order", "asc");
      setData((prev) => ({ ...prev, socials: sorted }));
    });
  }

  useEffect(populateFooterData, []);

  return { data };
}
