import React from "react";
import { BrowserRouter } from "react-router-dom";

import Layout from "@layout/Layout";
import AppRouter from "@routes/AppRouter";

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";

import "./Overwrite.scss";

export default function App() {
  return (
    <BrowserRouter>
      <Layout>
        <AppRouter />
      </Layout>
    </BrowserRouter>
  );
}
