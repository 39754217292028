import React from "react";
import styles from "./index.module.scss";

import clsx from "clsx";

export default function WithPadding({ children, id, className, style }) {
  return (
    <div id={id} className={clsx(styles.wrapper, className)} style={style}>
      {children}
    </div>
  );
}
