import { useEffect, useState } from "react";
import { useWindowResize } from "beautiful-react-hooks";

export default function useNavbar() {
  const [isOpenHam, setIsOpenHam] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  function handleClickHam() {
    setIsOpenHam(!isOpenHam);
  }

  function mobileViewResizeHandler() {
    if (window.innerWidth < 800) setIsMobileView(true);
    else setIsMobileView(false);
  }

  useWindowResize(mobileViewResizeHandler);

  useEffect(() => {
    mobileViewResizeHandler();
  }, []);

  return { isOpenHam, handleClickHam, isMobileView };
}
