import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import InlineLoader from "@components/InlineLoader";

const FinancialLiteracy = lazy(() => import("@pages/FinancialLiteracy"));
const Home = lazy(() => import("@pages/Home"));
const Courses = lazy(() => import("@pages/Courses"));
const Contact = lazy(() => import("@pages/Contact"));
const Blogs = lazy(() => import("@pages/Blogs"));
const Blog = lazy(() => import("@pages/Blog"));
const Products = lazy(() => import("@pages/Products"));
const About = lazy(() => import("@pages/About"));

export default function AppRouter() {
  return (
    <Suspense fallback={<InlineLoader height="90vh" />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:blogId" element={<Blog />} />
        <Route path="/products" element={<Products />} />
        <Route path="/financialliteracy" element={<FinancialLiteracy />} />
      </Routes>
    </Suspense>
  );
}
