import React from "react";
import style from "./index.module.scss";

import { useLocation, Link, useNavigate } from "react-router-dom";

import clsx from "clsx";
import HamburgerMenu from "react-hamburger-menu";

import WithPadding from "@components/WithPadding";
import useNavbar from "./useNavbar";

import LogoImg from "@assets/logo.svg";
import _Links from "@utils/navigation-links.json";

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const { isOpenHam, isMobileView, handleClickHam } = useNavbar();

  return (
    <WithPadding className={style.wrapper}>
      {isMobileView && (
        <div className={style.ham}>
          <HamburgerMenu isOpen={isOpenHam} menuClicked={handleClickHam} width={25} height={15} color="#a34791" />
        </div>
      )}

      <div onClick={() => navigate("/")} className={style.logo}>
        <img src={LogoImg} alt="" />
      </div>

      <nav className={clsx(style.links, isMobileView && style.mobileView, isOpenHam && style.open)}>
        <ul>
          {_Links.map((link) => (
            <li className={clsx(location.pathname === link.link && style.linkActive)} key={link.name}>
              <Link onClick={handleClickHam} to={link.link}>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </WithPadding>
  );
}
