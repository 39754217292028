import React from "react";
import style from "./index.module.scss";

import Loader from "react-spinners/BounceLoader";
import Logo from "@assets/logo.svg";

export default function InlineLoader({ height }) {
  return (
    <div className={style.wrapper} style={{ height: height ? height : "200px" }}>
      <img src={Logo} alt="" />
      <Loader color={"#a34791"} size={300} />
    </div>
  );
}
