import axios from "axios";
import config from "@config/config.js";
import axiosRetry from "axios-retry";

const axiosClient = axios.create({
  baseURL: config.apiBaseUrl,
  timeout: 10000,
});

axiosRetry(axiosClient, { retries: 2 });

export const getRequest = (path) => {
  return axiosClient.get(path);
};

export const postRequest = (path, data) => {
  return axiosClient.post(path, data);
};
