import { getRequest, postRequest } from "./setup";

export function getFeatures() {
  return getRequest(`/general/features`);
}

export function getTestimonials() {
  return getRequest(`/general/testimonials`);
}

export function postContact(postBody) {
  return postRequest(`/general/create-contact/`, postBody);
}

export function getFaq() {
  return getRequest(`/general/faq`);
}

export function getTeam() {
  return getRequest(`/teams`);
}

export function getAbout() {
  return getRequest(`/general/about`);
}

export function getSocials() {
  return getRequest(`/general/socials`);
}
